import React from 'react'
import {
  CitaPreviaState,
  SelectedService,
} from '../../../../context/reducers/citaPreviaReducer'
import ServicesList from '../ServicesList/ServicesList'
import styles from './index.module.scss'
import { t } from '../../../../../common/i18n'
import { ServiceTaller } from '../Servicios'

interface Props {
  activeTab: 'Coche' | 'Moto' | 'Coche electrico' | 'Patinete'
  containerState: CitaPreviaState
  onSelectService: (selectedService: SelectedService) => void
  onMoreInfo: (service: ServiceTaller) => void
}

const patineteServices = [
  {
    service: 'cam',
    nombre_es: 'Cámara',
    nombre_ca: 'Càmera',
    nombre_pt: 'Càmera',
    more_info_es: null,
    more_info_ca: null,
    more_info_pt: null,
  },
  {
    service: 'cub',
    nombre_es: 'Cubierta',
    nombre_ca: 'Coberta',
    nombre_pt: 'Coberta',
    more_info_es: null,
    more_info_ca: null,
    more_info_pt: null,
  },
  {
    service: 'camcub',
    nombre_es: 'Cubierta + Cámara',
    nombre_ca: 'Coberta + Càmera',
    nombre_pt: 'Coberta + Càmera',
    more_info_es: null,
    more_info_ca: null,
    more_info_pt: null,
  },
  {
    service: 'rum',
    nombre_es: 'Rueda Maciza',
    nombre_ca: 'Roda Massissa',
    nombre_pt: 'Roda Massissa',
    more_info_es: null,
    more_info_ca: null,
    more_info_pt: null,
  },
]

export default function ServicesContainer({
  activeTab,
  containerState,
  onSelectService,
  onMoreInfo,
}: Props) {
  return (
    <div className={styles.services_content}>
      {activeTab === 'Coche' && (
        <React.Fragment>
          <span className={styles.title}>
            {t('cita_previa.selecciona_servicio')}{' '}
            {t(`cita_previa.servicios.${activeTab.toLowerCase()}`)}
          </span>
          <ServicesList
            onMoreInfo={onMoreInfo}
            activeTab={activeTab}
            services={containerState.availableServices.coche}
            onSelectService={onSelectService}
            currentSelection={
              containerState.selectedService
                ? containerState.selectedService.service
                : null
            }
            selectedTaller={containerState.selectedTaller || null}
          />
        </React.Fragment>
      )}
      {activeTab === 'Coche electrico' && (
        <React.Fragment>
          <span className={styles.title}>
            {t('cita_previa.selecciona_servicio')}{' '}
            {t(`cita_previa.servicios.${activeTab.toLowerCase()}`)}
          </span>
          <ServicesList
            onMoreInfo={onMoreInfo}
            activeTab={activeTab}
            services={containerState.availableServices.coche}
            onSelectService={onSelectService}
            currentSelection={
              containerState.selectedService
                ? containerState.selectedService.service
                : null
            }
          />
        </React.Fragment>
      )}
      {activeTab === 'Moto' && (
        <React.Fragment>
          <span className={styles.title}>
            {t('cita_previa.selecciona_servicio_moto')}{' '}
            {t(`cita_previa.servicios.${activeTab.toLowerCase()}`)}
          </span>
          <ServicesList
            onMoreInfo={onMoreInfo}
            activeTab={activeTab}
            services={containerState.availableServices.moto}
            onSelectService={onSelectService}
            currentSelection={
              containerState.selectedService
                ? containerState.selectedService.service
                : null
            }
          />
        </React.Fragment>
      )}
      {activeTab === 'Patinete' && (
        <React.Fragment>
          <span className={styles.title}>
            {t('cita_previa.selecciona_servicio')}{' '}
            {t(`cita_previa.servicios.${activeTab.toLowerCase()}`)}
          </span>
          <ServicesList
            onMoreInfo={onMoreInfo}
            activeTab={activeTab}
            services={patineteServices}
            onSelectService={onSelectService}
            currentSelection={
              containerState.selectedService
                ? containerState.selectedService.service
                : null
            }
          />
        </React.Fragment>
      )}
    </div>
  )
}

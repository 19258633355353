import { navigate } from '@reach/router'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Coche from '../../../assets/cita-previa/icon_coche.svg'
import CocheElectrico from '../../../assets/cita-previa/icon_coche_electrico.svg'
import Moto from '../../../assets/cita-previa/icon_moto.svg'
import Patinete from '../../../assets/cita-previa/icon_patinete.svg'
import {
  getAvailableServices,
  setCitaPreviaStep,
  setSelectedService,
  setVehicleType,
} from '../../../context/actions/citaPreviaActions'
import AppContext from '../../../context/context'
import { SelectedService } from '../../../context/reducers/citaPreviaReducer'
import { t, textWithLocale } from '../../../i18n'
import ServicioMoreInfo from './ServicioMoreInfo/ServicioMoreInfo'
import Size from '../../../utils/media-queries-setup'
import { alertProp } from '../../../utils/swal'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import Next from '../../../assets/images/Talleres/next.svg'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import StandardModal from '../../modal/standardModal/StandardModal'
import SpinnerWithText from '../../spinnerWithText/SpinnerWithText'
import TabsWithoutRoute from '../../tabsAux/TabsWithoutRoute'
import { CitaPreviaEnum, citaPreviaSteps } from '../CitaPreviaSteps'
import styles from './index.module.scss'
import ServicesContainer from './ServicesContainer/ServicesContainer'
import Button from '../../buttons/Button'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'
import { StepDetails } from '../../FunnelSelector/SelectorSteps'

type ActiveTab = 'Coche' | 'Coche electrico' | 'Moto'

export interface ServiceTaller {
  service: string
  nombre_es: string
  nombre_ca: string
  nombre_pt: string
  more_info_es: string | null
  more_info_ca: string | null
  more_info_pt: string | null
}

export default function Servicios({ nextStep, location }: CitaPreviaPageProps) {
  const [showModal, setShowModal] = useState(false)
  const [moreInfoService, setMoreInfoService] = useState(null)
  const [comingFromMotoTalleres, setComingFromTalleres] = useState(
    location && location.state.moto
  )
  const { citaPreviaState, dispatch } = useContext(AppContext)
  let activeTab: ActiveTab
  if (citaPreviaState.vehicleTypes) {
    if (comingFromMotoTalleres) {
      activeTab = citaPreviaState.vehicleTypes.find(
        (item) => item.tab === 'Moto'
      ).tab
    } else {
      activeTab = citaPreviaState.vehicleTypes.find(
        (item) => item.active === true
      ).tab
    }
  }
  useEffect(() => {
    dispatch(
      getAvailableServices(
        citaPreviaState.selectedTaller.codigo_taller,
        citaPreviaState.selectedService
      )
    )
  }, [])
  const onChangeTab = (id: number) => {
    setComingFromTalleres(false)
    const newTabs = citaPreviaState.vehicleTypes.map((item) => ({
      ...item,
      active: item.id === id,
    }))
    dispatch(setVehicleType(newTabs))
  }

  const onSelectService = (selectedService: SelectedService) => {
    dispatch(setSelectedService(selectedService))
  }

  const onMoreInfo = (service: SelectedService) => {
    setMoreInfoService(service)
    setShowModal(true)
  }

  const onContinue = () => {
    if (!citaPreviaState.selectedService) {
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('cita_previa.servicios.required'),
        })
      )
      return null
    }
    if (citaPreviaState.selectedService.service === 'cambneum') {
      const next = StepDetails[0]
      navigate(next.routes.desktop, { replace: next.replace })
      return false
    }
    dispatch(setCitaPreviaStep(nextStep.step))
    navigate(nextStep.route)
  }

  const onGoBack = () => {
    dispatch(
      setCitaPreviaStep(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].step)
    )
    navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route)
  }

  const icon = (vehicleType) => {
    switch (vehicleType.tab) {
      case 'Coche':
        return <Coche />
      case 'Coche electrico':
        return <CocheElectrico />
      case 'Moto':
        return <Moto />
      case 'Patinete':
        return <Patinete />
      default:
        return null
    }
  }

  let tabsArray
  if (comingFromMotoTalleres) {
    tabsArray =
      citaPreviaState.vehicleTypes &&
      citaPreviaState.vehicleTypes.map((vehicleType) => ({
        ...vehicleType,
        active: vehicleType.tab === 'Moto',
        icon: icon(vehicleType),
      }))
  } else {
    tabsArray =
      citaPreviaState.vehicleTypes &&
      citaPreviaState.vehicleTypes.map((vehicleType) => ({
        ...vehicleType,
        icon: icon(vehicleType),
      }))
  }
  return (
    <LayoutSelector
      stepActive={citaPreviaState.step}
      customSteps={citaPreviaSteps}
      title={t('cita_previa.servicios.title')}
      subTitle={t('cita_previa.servicios.subTitle')}
      mobileStep={citaPreviaState.step}
      totalSteps={5}
      showCopyrightFooter>
      <div className={styles.horarios_container}>
        <div className={styles.horarios_container_left}>
          {citaPreviaState.loading ? <SpinnerWithText text="" /> : null}
          {citaPreviaState.error ? (
            <p className={styles.error_msg}>
              {t('cita_previa.servicios.error_cargando')}
            </p>
          ) : null}
          {!citaPreviaState.loading && !citaPreviaState.error ? (
            <React.Fragment>
              <TabsWithoutRoute
                onChangeTab={onChangeTab}
                tabsArray={tabsArray}
                className={styles.tab}
                dispatch={dispatch}
              />
              <ServicesContainer
                activeTab={activeTab}
                containerState={citaPreviaState}
                onSelectService={onSelectService}
                onMoreInfo={onMoreInfo}
              />
              {showModal ? (
                <StandardModal
                  title={moreInfoService[textWithLocale('nombre')]}
                  showModal={showModal}
                  closeModal={() => setShowModal(false)}
                  modalClass={styles.modal_servicios}>
                  <ServicioMoreInfo servicio={moreInfoService} />
                </StandardModal>
              ) : null}
            </React.Fragment>
          ) : null}
          <Size.Matcher
            mobile={
              activeTab &&
              citaPreviaState.availableServices[activeTab.toLowerCase()]
                .length > 0 && (
                <div className={styles.button_cont}>
                  <Button buttonClass="next_button" onClick={onContinue}>
                    {t('selector-desktop.continuar')}
                    <Next />
                  </Button>
                </div>
              )
            }
            desktop={
              activeTab &&
              citaPreviaState.availableServices[activeTab.toLowerCase()]
                .length > 0 ? (
                <CestaLastRow
                  onContinuarClick={onContinue}
                  onBackClick={onGoBack}></CestaLastRow>
              ) : (
                <CestaLastRow onBackClick={onGoBack}></CestaLastRow>
              )
            }
          />
        </div>
        <Size.Matcher
          mobile={null}
          desktop={
            <FunnelSideBar
              currentLocation="cita-previa"
              citaPreviaState={citaPreviaState}
            />
          }
        />
      </div>
    </LayoutSelector>
  )
}

const mainMarcas = [
  { value: 'VW', label: 'VW' },
  { value: 'RENAULT', label: 'RENAULT' },
  { value: 'TOYOTA', label: 'TOYOTA' },
  { value: 'CITROËN', label: 'CITROËN' },
  { value: 'OPEL', label: 'OPEL' },
  { value: 'SEAT', label: 'SEAT' },
  { value: 'PEUGEOT', label: 'PEUGEOT' },
  { value: 'FORD', label: 'FORD' },
  { value: 'NISSAN', label: 'NISSAN' },
  { value: 'HYUNDAI', label: 'HYUNDAI' },
  { value: 'BMW', label: 'BMW' },
  { value: 'MERCEDES-BENZ', label: 'MERCEDES-BENZ' },
  { value: 'FIAT', label: 'FIAT' },
  { value: 'DACIA', label: 'DACIA' },
  { value: 'KIA', label: 'KIA' },
  { value: 'AUDI', label: 'AUDI' },
  { value: 'HONDA', label: 'HONDA' },
  { value: 'SKODA', label: 'SKODA' },
  { value: 'CHEVROLET', label: 'CHEVROLET' },
  { value: 'MAZDA', label: 'MAZDA' },
]

export default mainMarcas

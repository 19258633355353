import { navigate } from '@reach/router'
import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import Patinete from '../../../assets/cita-previa/icon_patinete.svg'

import {
  setScooterBrand,
  setCitaPreviaStep,
  setSelectedService,
} from '../../../context/actions/citaPreviaActions'
import AppContext from '../../../context/context'
import { SelectedService } from '../../../context/reducers/citaPreviaReducer'
import { t, textWithLocale } from '../../../i18n'
import ServicioMoreInfo from './ServicioMoreInfo/ServicioMoreInfo'
import Size from '../../../utils/media-queries-setup'
import { alertProp } from '../../../utils/swal'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import Next from '../../../assets/images/Talleres/next.svg'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import StandardModal from '../../modal/standardModal/StandardModal'
import SpinnerWithText from '../../spinnerWithText/SpinnerWithText'
import TabsWithoutRoute from '../../tabsAux/TabsWithoutRoute'
import { PatineteCitaPreviaEnum, patineteCitaSteps } from '../CitaPreviaSteps'
import styles from './index.module.scss'
import ServicesContainer from './ServicesContainer/ServicesContainer'
import Button from '../../buttons/Button'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'
import DropDown from '../../../components/dropDown/DropDown'

export interface ServiceTaller {
  service: string
  nombre_es: string
  nombre_ca: string
}

export default function ServiciosPatinete({ nextStep }: CitaPreviaPageProps) {
  const [showModal, setShowModal] = useState(false)
  const [moreInfoService, setMoreInfoService] = useState(null)

  const { citaPreviaState, dispatch } = useContext(AppContext)

  const onSelectService = (selectedService: SelectedService) => {
    dispatch(setSelectedService(selectedService))
  }

  const onMoreInfo = (service: SelectedService) => {
    setMoreInfoService(service)
    setShowModal(true)
  }

  const onContinue = () => {
    if (!citaPreviaState.selectedService) {
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('cita_previa.servicios.required'),
        })
      )
      return null
    }
    dispatch(setCitaPreviaStep(nextStep.step))
    navigate(nextStep.route)
  }

  const onGoBack = () => {
    dispatch(
      setCitaPreviaStep(patineteCitaSteps[PatineteCitaPreviaEnum.MARCA].step)
    )
    navigate(patineteCitaSteps[PatineteCitaPreviaEnum.MARCA].route)
  }

  const onChangeSelection = (e) => {
    const newMarca = citaPreviaState.scooter.scooterBrands.find(
      (brand) => brand.marca === e.target.value
    )
    dispatch(setScooterBrand(newMarca))
  }

  return (
    <LayoutSelector
      stepActive={patineteCitaSteps[1].step}
      customSteps={patineteCitaSteps}
      title={t('cita_previa.patinete.title')}
      subTitle={t('cita_previa.patinete.subTitle')}
      mobileStep={patineteCitaSteps[1].step}
      showCopyrightFooter>
      <div className={styles.horarios_container}>
        <div className={styles.horarios_container_left}>
          {citaPreviaState.loading ? <SpinnerWithText text="" /> : null}
          {citaPreviaState.error ? (
            <p className={styles.error_msg}>
              {t('cita_previa.servicios.error_cargando')}
            </p>
          ) : null}
          {!citaPreviaState.loading && !citaPreviaState.error ? (
            <React.Fragment>
              <TabsWithoutRoute
                onChangeTab={() => {
                  // nothing
                }}
                tabsArray={[
                  {
                    id: 1,
                    tab: 'Patinete',
                    displayName_ca: 'Patinet',
                    displayName_es: 'Patinete',
                    displayName_pt: 'Scooter',
                    active: true,
                    icon: <Patinete />,
                  },
                ]}
                className={styles.tab}
                dispatch={dispatch}
              />
              <div className={styles.brand_selector_container}>
                <p className={styles.title}>
                  {t('cita_previa.patinete.selecciona_marca')}
                </p>
                <DropDown
                  size="small"
                  onChange={onChangeSelection}
                  defaultValue={citaPreviaState.scooter.selectedBrand.marca}
                  options={citaPreviaState.scooter.scooterBrands.map(
                    (brand) => ({
                      value: brand.marca,
                      label: brand.marca.toUpperCase(),
                    })
                  )}
                  labelId={` `}
                  labelAbove={true}
                />
              </div>
              <ServicesContainer
                activeTab={'Patinete'}
                containerState={citaPreviaState}
                onSelectService={onSelectService}
                onMoreInfo={onMoreInfo}
              />
              {showModal ? (
                <StandardModal
                  title={moreInfoService[textWithLocale('nombre')]}
                  showModal={showModal}
                  closeModal={() => setShowModal(false)}
                  modalClass={styles.modal_servicios}>
                  <ServicioMoreInfo servicio={moreInfoService} />
                </StandardModal>
              ) : null}
            </React.Fragment>
          ) : null}
          <Size.Matcher
            mobile={
              <div className={styles.button_cont}>
                <Button buttonClass="next_button" onClick={onContinue}>
                  {t('selector-desktop.continuar')}
                  <Next />
                </Button>
              </div>
            }
            desktop={
              <CestaLastRow
                onContinuarClick={onContinue}
                onBackClick={onGoBack}></CestaLastRow>
            }
          />
        </div>
        <Size.Matcher
          mobile={null}
          desktop={
            <FunnelSideBar
              currentLocation="cita-previa"
              citaPreviaState={citaPreviaState}
            />
          }
        />
      </div>
    </LayoutSelector>
  )
}

import React from 'react'
import styles from './radioButtonYellow.module.scss'

interface Props {
  label1: string | JSX.Element
  label2?: string | JSX.Element
  name: string
  value: string | number | string[] | boolean
  onChange: (value: string | number | string[] | boolean) => void
  checked: boolean
  radioRequired?: boolean
  formRef?: React.LegacyRef<HTMLInputElement>
  variant?: 'small' | 'xsmall'
  disabled?: boolean
}
const RadioButtonYellow = ({
  label1,
  label2 = '',
  name,
  value,
  onChange,
  checked,
  radioRequired,
  formRef,
  variant,
  disabled,
}: Props) => {
  const classes = [styles.container]
  if (label2) {
    classes.push(styles.container_labels)
  }
  if (variant) {
    classes.push(styles[variant])
  }

  return (
    <label className={classes.join(' ')}>
      <input
        type="radio"
        checked={checked}
        name={name}
        ref={formRef}
        value={value.toString()}
        required={radioRequired}
        onChange={() => onChange(value)}
        disabled={disabled}
      />
      <span className={styles.checkmark}></span>
      {label1}
      {label2}
    </label>
  )
}

export default RadioButtonYellow

export default [
  {
    name: 'Guarda',
    postCode: '6300-000',
  },
  {
    name: 'Fundão',
    postCode: '6230-483',
  },
  {
    name: 'Castelo Branco',
    postCode: '6000-459',
  },
  {
    name: 'Porto',
    postCode: '4450-608',
  },
]

import React, { useContext } from 'react'
import AppContext from '../../context/context'

function useCitaPreviaRedirect(step) {
  const { citaPreviaState } = useContext(AppContext)
  switch (step) {
    // services
    case 1:
      return Object.keys(citaPreviaState.selectedTaller).length === 0
    // horarios
    case 2:
      return (
        Object.keys(citaPreviaState.selectedTaller).length === 0 ||
        !citaPreviaState.selectedService
      )
    // datos
    case 3:
      return (
        Object.keys(citaPreviaState.selectedTaller).length === 0 ||
        !citaPreviaState.selectedService ||
        !citaPreviaState.selectedDate.date ||
        !citaPreviaState.selectedDate.time
      )
    // confirmation
    case 4:
      return (
        Object.keys(citaPreviaState.selectedTaller).length === 0 ||
        !citaPreviaState.selectedService ||
        !citaPreviaState.selectedDate.date ||
        !citaPreviaState.selectedDate.time
      )
    default:
      return false
  }
}
export default useCitaPreviaRedirect

import React, { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form/dist/types'
import { FormattedMessage } from 'react-intl'
import AppContext from '../../../../context/context'
import { UserState } from '../../../../context/reducers/userReducer'
import { t, textWithLocale } from '../../../../i18n'
import { Taller } from '../../../../types/Taller'
import route from '../../../../utils/route'
import CheckBoxYellowCesta from '../../../checkboxYellowCesta/CheckBoxYellowCesta'
import ErrorMessage from '../../../error/Error'
import FormError from '../../../FormError/FormError'
import InputCesta from '../../../inputCesta/InputCesta'
import InputCestaDropDown from '../../../inputCesta/InputCestaDropDown'
import StandardModal from '../../../modal/standardModal/StandardModal'
import styles from './index.module.scss'

export interface DatosUsuarioFormGuest {
  name: string
  email: string
  phone: string
  matricula: string
  marca: string
  modelo: string
  checkboxPrivacy: string
  checkboxRenting: string
  matricula_moto: string
  matriculaMarcaModelo: string
  comment: string
}

export type DatosFormErrors = FieldErrors<DatosUsuarioFormGuest>

interface Props {
  formRef: any
  errors: DatosFormErrors
  globalError?: string
  userInfo: UserState
  vehicleType: string
  onChangeMarca: (event: any) => void
  marcasOptions: { value: string; label: string }[]
  modelosOptions: { value: string; label: string }[]
  vehicles: { value: string; label: string }[]
  onShowDropDowns: () => void
  showDropDowns: boolean
}

const DatosUsuarioForm = ({
  userInfo,
  formRef,
  errors,
  globalError,
  vehicleType,
  marcasOptions,
  modelosOptions,
  onChangeMarca,
  vehicles,
  onShowDropDowns,
  showDropDowns,
}: Props) => {
  const { citaPreviaState } = useContext(AppContext)
  const [moreInfoRenting, setMoreInfoRenting] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const onMoreInfo = (taller: Partial<Taller>) => {
    setMoreInfoRenting(
      <>
        <p>{t('cita_previa.servicios.renting_text_1')}</p>
        <br />
        <p>
          {t('cita_previa.servicios.renting_text_2', {
            taller: taller.alias,
            telTaller: taller.telefono,
          })}
        </p>
      </>
    )
    setShowModal(true)
  }
  return (
    <div className={styles.register_form_container}>
      {showModal ? (
        <StandardModal
          title={t('cita_previa.renting_info')}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          modalClass={styles.modal_servicios}>
          <div>{moreInfoRenting}</div>
        </StandardModal>
      ) : null}
      <p className={styles.intro_text}>
        <FormattedMessage id="registerForm.intro_text" />
      </p>
      <InputCesta
        label={<FormattedMessage id="registerForm.name" />}
        name="name"
        type="text"
        defaultValue={userInfo.userName || ''}
        inputSize="big"
        refe={formRef}
        errors={errors}
      />
      <InputCesta
        label={<FormattedMessage id="registerForm.email" />}
        name="email"
        inputSize="big"
        type="text"
        defaultValue={userInfo.email || ''}
        refe={formRef}
        errors={errors}
      />
      <InputCesta
        label={<FormattedMessage id="registerForm.phone" />}
        name="phone"
        type="text"
        defaultValue={userInfo.phone || ''}
        refe={formRef}
        errors={errors}
      />
      <p className={styles.pick_password_text}>
        <FormattedMessage id="datos_cita_previa.datos_vehiculo" />
      </p>

      {vehicles.length &&
      vehicleType !== 'Moto' &&
      vehicleType !== 'Patinete' &&
      !showDropDowns ? (
        <React.Fragment>
          <InputCestaDropDown
            labelId="datos_cita_previa.tus_vehiculos"
            size="normal"
            formRef={formRef}
            name="matriculaMarcaModelo"
            errors={errors}
            options={vehicles}
            labelAbove={true}
          />
          <button onClick={onShowDropDowns} className={styles.add_vehicle}>
            + <FormattedMessage id="cita_previa.añadir_vehiculo" />
          </button>
        </React.Fragment>
      ) : null}

      {vehicleType !== 'Moto' && vehicleType !== 'Patinete' && showDropDowns ? (
        <React.Fragment>
          <InputCesta
            label={<FormattedMessage id="datos_cita_previa.matricula" />}
            name="matricula"
            type="text"
            maxLength={10}
            defaultValue={
              (citaPreviaState.userData &&
                citaPreviaState.userData.matricula) ||
              ''
            }
            refe={formRef}
            errors={errors}
          />
          <InputCestaDropDown
            labelId="datos_cita_previa.marca"
            size="normal"
            name="marca"
            formRef={formRef}
            onChange={onChangeMarca}
            defaultSelected={
              citaPreviaState.modificando
                ? citaPreviaState.userData.marca
                : null
            }
            errors={errors}
            options={marcasOptions}
            labelAbove={true}
          />
          <InputCestaDropDown
            labelId="datos_cita_previa.modelo"
            size="normal"
            formRef={formRef}
            defaultSelected={
              citaPreviaState.modificando
                ? citaPreviaState.userData.modelo
                : null
            }
            name="modelo"
            errors={errors}
            options={modelosOptions}
            labelAbove={false}
          />
        </React.Fragment>
      ) : null}

      {vehicleType === 'Moto' ? (
        <React.Fragment>
          <InputCesta
            label={<FormattedMessage id="datos_cita_previa.matricula" />}
            name="matricula_moto"
            type="text"
            maxLength={10}
            defaultValue={
              citaPreviaState.userData && citaPreviaState.userData.matricula
            }
            refe={formRef}
            errors={errors}
          />
          <InputCesta
            label={<FormattedMessage id="datos_cita_previa.marca" />}
            name="marca"
            type="text"
            defaultValue={
              citaPreviaState.userData && citaPreviaState.userData.marca
            }
            refe={formRef}
            errors={errors}
          />
          <InputCesta
            label={<FormattedMessage id="datos_cita_previa.modelo" />}
            name="modelo"
            type="text"
            defaultValue={
              citaPreviaState.userData && citaPreviaState.userData.modelo
            }
            refe={formRef}
            errors={errors}
          />
        </React.Fragment>
      ) : null}
      {vehicleType === 'Patinete' ? (
        <div className={styles.comment_wrapper}>
          <label>
            <FormattedMessage id="registerForm.servicio" />
          </label>
          <div>{citaPreviaState.selectedService[textWithLocale('nombre')]}</div>
        </div>
      ) : null}
      <div className={styles.comment_wrapper}>
        <label>
          <FormattedMessage id="registerForm.coment" />
        </label>
        <div>
          <textarea
            name="comment"
            ref={formRef}
            rows={2}
            defaultValue={
              (citaPreviaState.userData && citaPreviaState.userData.comment) ||
              ''
            }
          />
          <FormError errors={errors} field="comment" />
        </div>
      </div>
      <div className={styles.checkbox_container}>
        <div className={styles.checkboxYellow_wrapper}>
          <CheckBoxYellowCesta
            label={
              <div className={styles.label}>
                <FormattedMessage id="registrado.acepto" />
              </div>
            }
            name="checkboxSpam"
            refe={formRef}
          />
        </div>
        <div className={styles.checkboxYellow_wrapper}>
          <CheckBoxYellowCesta
            label={
              <div className={styles.label}>
                <FormattedMessage id="cita_previa.renting_checkbox" />{' '}
                <a
                  className={styles.spanlabel}
                  onClick={() => onMoreInfo(citaPreviaState.selectedTaller)}>
                  <FormattedMessage id="cita_previa.renting_info" />
                </a>
              </div>
            }
            name="checkboxRenting"
            refe={formRef}
          />
          <FormError errors={errors} field="checkboxRenting" />
        </div>
        <div className={styles.checkboxYellow_wrapper}>
          <CheckBoxYellowCesta
            checked={!!userInfo.loggedIn}
            label={
              <div className={styles.label}>
                <span>
                  <FormattedMessage id="registerForm.checkbox-privacy-read" />
                </span>
                <a
                  className={styles.spanlabel}
                  href={route('politica-privacidad.index')}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="registerForm.checkbox-privacy-policy" />
                </a>
              </div>
            }
            name="checkboxPrivacy"
            refe={formRef}
          />
          <FormError errors={errors} field="checkboxPrivacy" />
          {globalError && <ErrorMessage message={globalError}></ErrorMessage>}
        </div>
      </div>
    </div>
  )
}

export default DatosUsuarioForm

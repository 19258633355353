import { Link, navigate } from 'gatsby'
import React, { useContext, useRef, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactToPrint from 'react-to-print'
import Calendar from '../../../assets/images/area_privada/icono_cita.svg'
import Done from '../../Done/Done'
import Location from '../../../assets/images/Talleres/icon_localizacion3.svg'
import Phone from '../../../assets/images/Talleres/icon_telefo.svg'
import AppContext from '../../../context/context'
import { t } from '../../../../common/i18n'
import printer from '../../../../common/images/imprimir.png'
import Size from '../../../utils/media-queries-setup'
import route from '../../../utils/route'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import { citaPreviaSteps, areaPrivadaCitaSteps } from '../CitaPreviaSteps'
import ProductsList from '../Servicios/ProductsList/ProductsList'
import ComponentToPrint from './componentToPrint'
import styles from './index.module.scss'
import { getShortCompany } from '../../../utils/getCompany'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'
import { alertProp } from '../../../utils/swal'
import Swal from 'sweetalert2'

export default function Confirmacion({
  location: {
    state: { area_privada },
  },
}: CitaPreviaPageProps) {
  const componentRef = useRef()
  const { userInfo, citaPreviaState } = useContext(AppContext)
  const { userData } = userInfo
  useEffect(() => {
    if (area_privada && citaPreviaState.errors) {
      navigate(route('area-privada.index'))
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t(citaPreviaState.errors[0].message),
        })
      )
    }
  }, [citaPreviaState.errors])

  const customSteps = area_privada ? areaPrivadaCitaSteps : citaPreviaSteps

  return (
    <LayoutSelector
      stepActive={citaPreviaState.step}
      customSteps={customSteps}
      title={t('cita_previa.talleres.title')}
      mobileStep={citaPreviaState.step}
      totalSteps={citaPreviaSteps.length - 1}
      showCopyrightFooter>
      <div className={styles.reserva_container_left}>
        <div className={styles.reserva_container}>
          <Size.Matcher
            desktop={
              <div className={styles.print_cont}>
                <ReactToPrint
                  trigger={() => (
                    <button className={styles.imprimir_button}>
                      <img src={printer} alt="Imprimir" />
                      <FormattedMessage id="cesta.reserva.imprimir" />
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <div className={styles.content_print}>
                  <ComponentToPrint data={citaPreviaState} ref={componentRef} />
                </div>
              </div>
            }
            mobile={null}
          />
          <div className={styles.icon_mini}>
            <Done />
          </div>
          <p className={styles.text_cita_reservada}>
            {t('cesta.reserva.cita_reservada', {
              nombre_cliente: userData ? userData.name : '',
            })}{' '}
          </p>
          <Size.Matcher
            desktop={null}
            tablet={null}
            mobile={
              <div className={styles.reserva_container_mobile}>
                <span className={styles.tu_reserva_text}>
                  <FormattedMessage id="cesta.reserva.tu_reserva" />
                </span>
                <ProductsList citaPreviaState={citaPreviaState} />
              </div>
            }
          />

          <div className={styles.consulta_container}>
            <p className={styles.subtitle_cita_reservada}>
              <FormattedMessage id="cesta.reserva.consulta" />
            </p>

            <div className={styles.pedido_info_container}>
              <div className={styles.numero_solicitud_container}>
                <div className={styles.numero_solicitud}>
                  <span className={styles.numero_solicitud_text}>
                    <FormattedMessage id="cesta.reserva.numero_solicitud" />
                  </span>
                  <span className={styles.numero_solicitud_num}>
                    {citaPreviaState.citaPreviaId}
                  </span>
                </div>
              </div>
              {citaPreviaState.selectedTaller ? (
                <div className={styles.reserva_info_container}>
                  <div className={styles.reserva_location}>
                    <span className={styles.alias}>
                      <Location className={styles.marker_icon} />
                      {getShortCompany()} {citaPreviaState.selectedTaller.alias}
                    </span>
                    <div className={styles.adress}>
                      {citaPreviaState.selectedTaller.direccion}
                      {citaPreviaState.selectedTaller.poblacion}
                    </div>
                    <div className={styles.adress}>
                      ({citaPreviaState.selectedTaller.provincia})-
                      {citaPreviaState.selectedTaller.cpostal}
                    </div>
                  </div>
                  <div className={styles.reserva_horario}>
                    <span className={styles.date}>
                      <Calendar className={styles.calendar_icon} />
                      <div className={styles.date_text}>
                        {t('cesta.reserva.fecha_y_hora', {
                          date: citaPreviaState.selectedDate.date,
                          time: citaPreviaState.selectedDate.time,
                        })}
                      </div>
                    </span>
                    <span className={styles.phone}>
                      <Phone className={styles.marker_icon} />
                      {citaPreviaState.selectedTaller.telefono}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.button_container}>
              <a className={styles.goToButton} href="/">
                {' '}
                {t('cita_previa.volver_inicio')}
              </a>
            </div>
          </div>
        </div>

        <div className={styles.mas_info_container}>
          <p className={styles.interes}>
            <FormattedMessage id="cesta.reserva.interes" />
          </p>
          <Link className={styles.interes_text} to={route('revisiones.index')}>
            <FormattedMessage id="cesta.reserva.mas_info_revision" />
          </Link>
          <Link className={styles.interes_text} to={route('empresa.index')}>
            <FormattedMessage id="cesta.reserva.mas_info_historia" />
          </Link>
        </div>
      </div>
      <Size.Matcher
        desktop={
          <FunnelSideBar
            currentLocation="cita-previa"
            citaPreviaState={citaPreviaState}
          />
        }
        mobile={null}
      />
    </LayoutSelector>
  )
}

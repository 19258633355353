import React from 'react'
import styles from './tabsWithoutRoute.module.scss'
import { textWithLocale } from '../../../common/i18n'
import Size from '../../utils/media-queries-setup'
import { navigate } from 'gatsby-link'
import route from '../../utils/route'
import { setCitaPreviaStep } from '../../context/actions/citaPreviaActions'
import {
  PatineteCitaPreviaEnum,
  patineteCitaSteps,
} from '../CitaPrevia/CitaPreviaSteps'

export interface TabsWithoutRouteDefinition {
  id: number
  tab: string
  displayName_es: string
  displayName_ca: string
  displayName_pt: string
  active: boolean
  icon: JSX.Element
}
interface Props {
  tabsArray: TabsWithoutRouteDefinition[]
  className?: string
  onChangeTab: (id: number) => void
  dispatch: (action: object) => void
}
const TabsWithoutRoute = ({ tabsArray, onChangeTab, dispatch }: Props) => {
  if (tabsArray) {
    return (
      <div className={styles.container}>
        {tabsArray.map(({ tab, active, id, icon, ...rest }) => (
          <div
            onClick={() => {
              if (tab === 'Patinete') {
                dispatch(
                  setCitaPreviaStep(
                    patineteCitaSteps[PatineteCitaPreviaEnum.MARCA].step
                  )
                )
                navigate(route('cita-previa.marca_patinete'))
              } else {
                onChangeTab(id)
              }
            }}
            key={tab === 'Patinete' ? 'patinete' : id}
            className={active ? styles.tab_active : styles.tab}>
            {icon}
            <Size.Matcher desktop={rest[textWithLocale('displayName')]} />
          </div>
        ))}
      </div>
    )
  }
  return null
}

export default TabsWithoutRoute

import { navigate } from 'gatsby'
import React, { useContext, useEffect, useReducer } from 'react'
import Swal from 'sweetalert2'
import {
  resetSelection,
  setCitaPreviaStep,
  setCodigoPostalIntroducido,
  setSelectedTaller,
} from '../../../context/actions/citaPreviaActions'
import AppContext from '../../../context/context'
import { t } from '../../../../common/i18n'
import logic from '../../../logic'
import { SelectedTaller } from '../../../types/Taller'
import { alertProp } from '../../../utils/swal'
import TalleresContainer from '../../cesta/talleres/TalleresContainer'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import {
  citaPreviaSteps,
  areaPrivadaCitaSteps,
  CitaPreviaEnum,
  InStepDetails,
} from '../CitaPreviaSteps'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'

const maxNumTalleres: number =
  parseInt(process.env.GATSBY_MAX_NUM_TALLERES) || 10

const initialState = {
  searchedTalleres: [] as SelectedTaller[],
  loading: false as boolean,
  error: null as string | null,
}

type State = typeof initialState

function reducer(state: State, action) {
  switch (action.type) {
    case 'SET_TALLERES':
      return {
        ...state,
        loading: true,
      }
    case 'SET_TALLERES_OK':
      return {
        ...state,
        loading: false,
        searchedTalleres: action.payload.searchedTalleres,
      }
    case 'SET_TALLERES_FAILED':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case 'RESET_ERROR':
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}

export default function Talleres({
  path,
  nextStep,
  nextStepAreaPrivada,
  nextStepPatinete,
  location,
}: CitaPreviaPageProps) {
  const [localState, localDispatch] = useReducer(reducer, initialState)
  const { citaPreviaState, dispatch, userInfo } = useContext(AppContext)
  const area_privada = location.state ? location.state.area_privada : false
  // The following variable controls if we come from Edit option further on in the process
  // of Patinete and then we don't have to skip the Taller step as usually done in useEffect below.
  const edit_scooter = location.state ? location.state.edit_scooter : false
  const moto = path.includes('moto')

  useEffect(() => {
    if (
      !area_privada &&
      path.includes('patinet') &&
      !edit_scooter &&
      citaPreviaState.selectedTaller.codigo_taller
    ) {
      dispatch(setCitaPreviaStep(nextStepPatinete.step))
      navigate(nextStepPatinete.route)
    }
  }, [])

  const retrieveTalleres = (postCode: string) => {
    dispatch(setCodigoPostalIntroducido(postCode))
    localDispatch({
      type: 'SET_TALLERES',
    })
    logic
      .searchByPostCode(postCode, false, citaPreviaState.selectedService, moto)
      .then((talleres) => {
        localDispatch({
          type: 'SET_TALLERES_OK',
          payload: {
            searchedTalleres: talleres.tiendas,
          },
        })
      })
      .catch(() => {
        localDispatch({
          type: 'SET_TALLERES_FAILED',
          payload: {
            error: t('cesta.talleres.error_buscar_talleres', { postCode }),
          },
        })
      })
  }

  if (localState.error) {
    localDispatch({ type: 'RESET_ERROR' })
    Swal.fire(
      alertProp({
        type: 'error',
        text: localState.error,
        title: 'Oops...',
      })
    )
  }

  const geolocateMe = async () => {
    dispatch(setCodigoPostalIntroducido(''))
    if (!localState.loading) {
      localDispatch({
        type: 'SET_TALLERES',
      })
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          const { latitude, longitude } = pos.coords
          logic
            .retrieveTiendas(
              latitude,
              longitude,
              citaPreviaState.selectedService
            )
            .then((res) => {
              localDispatch({
                type: 'SET_TALLERES_OK',
                payload: {
                  searchedTalleres: res.slice(0, maxNumTalleres),
                },
              })
            })
        },
        () => {
          localDispatch({
            type: 'SET_TALLERES_FAILED',
            payload: {
              error: t('cesta.talleres.error_buscar_talleres'),
            },
          })
        },
        { timeout: 7000 }
      )
    }
  }

  const onSelectTaller = (taller: SelectedTaller) => {
    if (
      taller.codigo_taller !== citaPreviaState.selectedTaller.codigo_taller &&
      !citaPreviaState.fromRevision &&
      !area_privada
    ) {
      dispatch(resetSelection())
    }
    const origin = window.location.pathname
    let nextStepTo: InStepDetails = !area_privada
      ? nextStep
      : nextStepAreaPrivada
    let navigateState = { state: { area_privada, moto } }
    dispatch(setSelectedTaller(taller, origin))

    if (citaPreviaState.fromRevision) {
      nextStepTo = citaPreviaSteps[CitaPreviaEnum.SELECCION_HORARIO]
      navigateState = undefined
    }

    dispatch(setCitaPreviaStep(nextStepTo.step))
    navigate(nextStepTo.route, navigateState)
  }

  const customSteps = area_privada ? areaPrivadaCitaSteps : citaPreviaSteps

  return (
    <LayoutSelector
      stepActive={citaPreviaState.step}
      customSteps={customSteps}
      title={t('cita_previa.talleres.title')}
      subTitle={t('cita_previa.talleres.subTitle')}
      mobileStep={1}
      totalSteps={
        area_privada ? areaPrivadaCitaSteps.length : citaPreviaSteps.length
      }
      showCopyrightFooter>
      <TalleresContainer
        containerState={citaPreviaState}
        userInfo={userInfo}
        searchedTalleres={localState.searchedTalleres}
        retrieveTalleres={retrieveTalleres}
        nextAction={onSelectTaller}
        withDisponibilidad={false}
        loading={localState.loading}
        geolocateMe={geolocateMe}
      />
    </LayoutSelector>
  )
}

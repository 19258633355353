import React from 'react'
import { FormattedMessage } from 'react-intl'
import { t } from '../../../../common/i18n'
import Done from '../../Done/Done'
import Location from '../../../assets/images/Talleres/icon_localizacion3.svg'
import Calendar from '../../../assets/images/area_privada/icono_cita.svg'
import Phone from '../../../assets/images/Talleres/icon_telefo.svg'
import { CitaPreviaState } from '../../../context/reducers/citaPreviaReducer'

import './style.scss'
import { getShortCompany } from '../../../utils/getCompany'

interface PrintProps {
  data: CitaPreviaState
}
class ComponentToPrint extends React.Component<PrintProps> {
  render() {
    const { data } = this.props

    return (
      <div className="reserva_container">
        <div className="selector_header">
          <div className="header_wrapper">
            <a className="logo_wrapper" href="/">
              <img
                src="/logo-main.png"
                className="header_image"
                alt={`Logo ${getShortCompany()}`}></img>
            </a>
          </div>
        </div>
        <div className="icon_mini">
          <Done />
        </div>
        <p className="text_cita_reservada">
          <FormattedMessage id="cesta.reserva.cita_reservada" />
        </p>
        <div className="consulta_container">
          <p className="subtitle_cita_reservada">
            <FormattedMessage id="cesta.reserva.consulta" />
          </p>

          <div className="pedido_info_container">
            <div className="numero_solicitud_container">
              <div className="numero_solicitud">
                <span className="numero_solicitud_text">
                  <FormattedMessage id="cesta.reserva.numero_solicitud" />
                </span>
                <span className="numero_solicitud_num">
                  {data.citaPreviaId}
                </span>
              </div>
            </div>
            {data.selectedTaller ? (
              <div className="reserva_info_container">
                <div className="reserva_location">
                  <span className="alias">
                    <Location className="marker_icon" />
                    {getShortCompany()} {data.selectedTaller.alias}
                  </span>
                  <div className="adress">
                    {data.selectedTaller.direccion}
                    <br />
                    {data.selectedTaller.poblacion}
                  </div>
                  <div className="adress">
                    ({data.selectedTaller.provincia})-
                    {data.selectedTaller.cpostal}
                  </div>
                </div>
                <div className="reserva_horario">
                  <span className="date">
                    <Calendar className="calendar_icon" />
                    <div className="date_text">
                      {t('cesta.reserva.fecha_y_hora', {
                        date: data.selectedDate.date,
                        time: data.selectedDate.time,
                      })}
                    </div>
                  </span>
                  <span className="phone">
                    <Phone className="marker_icon" />
                    {data.selectedTaller.telefono}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
export default ComponentToPrint

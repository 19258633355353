import { Router } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import React, { useContext, useMemo } from 'react'
import {
  AreaPrivadaCitaPreviaEnum,
  areaPrivadaCitaSteps,
  CitaPreviaEnum,
  citaPreviaSteps,
  InStepDetails,
  PatineteCitaPreviaEnum,
  patineteCitaSteps,
  stepFromRoute,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import Confirmacion from '../../components/CitaPrevia/Confirmacion/Confirmacion'
import DatosUsuario from '../../components/CitaPrevia/DatosUsuario/DatosUsuario'
import Horarios from '../../components/CitaPrevia/Horarios/Horarios'
import Comentario from '../../components/CitaPrevia/Comentario/Comentario'
import Modificar from '../../components/CitaPrevia/Modificar/Modificar'
import Servicios from '../../components/CitaPrevia/Servicios/Servicios'
import ServiciosPatinete from '../../components/CitaPrevia/Servicios/ServiciosPatinete'
import Talleres from '../../components/CitaPrevia/Talleres/Talleres'
import Layout from '../../components/Layout'
import { SeoData } from '../../types/Seo'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import { setCitaPreviaStep } from '../../context/actions/citaPreviaActions'
import AppContext from '../../context/context'
import useCitaPreviaRedirect from '../../helpers/customHooks/useCitaPreviaRedirect'
import { t } from '../../i18n'
import route from '../../utils/route'
import styles from './citaPrevia.module.scss'
import MarcaPatinete from '../../components/CitaPrevia/MarcaPatinete/MarcaPatinete'

interface Props {
  data: {
    seoData: SeoData
  }
  area_privada?: boolean
}

export interface CitaPreviaPageProps {
  path: string
  nextStep?: InStepDetails
  nextStepAreaPrivada?: InStepDetails
  nextStepPatinete?: InStepDetails
  location?: {
    state: {
      key: string
      area_privada?: boolean
      moto?: boolean
      edit_scooter?: boolean
    }
  }
}

const CitaPrevia = ({ data }: Props) => {
  const { citaPreviaState, dispatch } = useContext(AppContext)

  let preventRender = typeof window === 'undefined'
  const path =
    typeof document !== 'undefined' ? document.location.pathname : '/'
  const requestedStep = useMemo(() => stepFromRoute(path), [path])

  const shouldRedirect = path.includes('moto')
    ? false
    : useCitaPreviaRedirect(requestedStep ? requestedStep.step : null)
  if (shouldRedirect) {
    navigate(route('index'))
    preventRender = true
  } else {
    preventRender = false
  }

  const onGoBack = () => {
    if (citaPreviaState.step === 0) {
      navigate(route('index'))
      return null
    }
    if (path.includes('patinet')) {
      if (citaPreviaState.step === 5) {
        return null
      }
      const newStep = citaPreviaState.step - 1
      const stepInfo = patineteCitaSteps.find((item) => item.step === newStep)
      dispatch(setCitaPreviaStep(newStep))
      navigate(stepInfo.route, {
        state: { edit_scooter: true },
      })
    } else {
      if (citaPreviaState.step === 4) {
        return null
      }
      const newStep = citaPreviaState.step - 1
      const stepInfo = citaPreviaSteps.find((item) => item.step === newStep)
      dispatch(setCitaPreviaStep(newStep))
      navigate(stepInfo.route)
    }
  }

  if (preventRender) {
    return null
  }

  let talleresRoute = citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route
  if (path.includes('moto')) {
    talleresRoute = route('cita-previa.seleccion_moto')
  } else if (path.includes('patinet')) {
    talleresRoute = route('cita-previa.seleccion_taller_patinete')
  }

  let horariosRoute = citaPreviaSteps[CitaPreviaEnum.SELECCION_HORARIO].route
  if (path.includes('patinet')) {
    horariosRoute = route('cita-previa.fecha_hora_patinete')
  }

  let datosRoute = citaPreviaSteps[CitaPreviaEnum.DATOS_USUARIO].route
  if (path.includes('patinet')) {
    datosRoute = route('cita-previa.tus_datos_patinete')
  }

  return (
    <Layout
      currentLocation="cita-previa"
      seoData={data.seoData}
      onGoBack={onGoBack}
      selector={true}>
      {citaPreviaState.loading && (
        <SpinnerWithText text={t('cesta.general.cargando')} />
      )}
      <div className={citaPreviaState.loading ? styles.hidden : undefined}>
        <Router>
          <Talleres
            path={talleresRoute}
            nextStep={citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO]}
            nextStepAreaPrivada={
              areaPrivadaCitaSteps[CitaPreviaEnum.SELECCION_HORARIO]
            }
            nextStepPatinete={
              patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_HORARIO]
            }
          />
          <Servicios
            path={citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].route}
            nextStep={citaPreviaSteps[CitaPreviaEnum.SELECCION_HORARIO]}
          />
          <ServiciosPatinete
            path={
              patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_SERVICIO].route
            }
            nextStep={
              patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_TALLER]
            }
          />
          <MarcaPatinete
            path={patineteCitaSteps[PatineteCitaPreviaEnum.MARCA].route}
            nextStep={
              patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_SERVICIO]
            }
          />
          <Comentario
            path={
              areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT]
                .route
            }
            nextStep={
              areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.SELECCION_TALLER]
            }
            nextStepAreaPrivada={
              areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.SELECCION_TALLER]
            }
          />
          <Horarios
            path={horariosRoute}
            nextStep={citaPreviaSteps[CitaPreviaEnum.DATOS_USUARIO]}
            nextStepAreaPrivada={
              areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.CONFIRMACION]
            }
            nextStepPatinete={
              patineteCitaSteps[PatineteCitaPreviaEnum.DATOS_USUARIO]
            }
          />
          <DatosUsuario
            path={datosRoute}
            nextStep={citaPreviaSteps[CitaPreviaEnum.CONFIRMACION]}
            nextStepPatinete={
              patineteCitaSteps[PatineteCitaPreviaEnum.CONFIRMACION]
            }
          />
          <Confirmacion
            path={citaPreviaSteps[CitaPreviaEnum.CONFIRMACION].route}
          />
          <Modificar path={route('cita-previa.modificar')} />
        </Router>
      </div>
    </Layout>
  )
}

export default CitaPrevia

export const pageQuery = graphql`
  query citapreviaPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`

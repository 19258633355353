import React from 'react'
import styles from './ServicioMoreInfo.module.scss'
import { textWithLocale } from '../../../../i18n'
import { ServiceTaller } from '../Servicios'

interface Props {
  servicio: ServiceTaller
}

export default function ServicioMoreInfo({ servicio }: Props) {
  const description = servicio[textWithLocale('more_info')]
  return (
    <div
      className={styles.modal_wrapper}
      dangerouslySetInnerHTML={{ __html: description || '' }}
    />
  )
}

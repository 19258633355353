import { navigate } from '@reach/router'
import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import { t } from '../../../../common/i18n'
import AppContext from '../../../context/context'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import RadioButtonYellow from '../../radioButtonYellow/RadioButtonYellow'
import Size from '../../../utils/media-queries-setup'
import route from '../../../utils/route'
import { alertProp } from '../../../utils/swal'
import { CestaPageProps } from '../../cesta/registro/CestaRegistro'
import Error from '../../error/Error'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import Next from '../../../assets/images/Talleres/next.svg'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import {
  deleteCitaPrevia,
  setCitaPreviaStep,
  setModifyingCitaPrevia,
  resetError,
  resetCitaPrevia,
} from '../../../context/actions/citaPreviaActions'
import { CitaPreviaEnum, citaPreviaSteps, PatineteCitaPreviaEnum, patineteCitaSteps } from '../CitaPreviaSteps'
import styles from './index.module.scss'
import Button from '../../buttons/Button'
import { FormattedMessage } from 'react-intl'

export default function Modificar({ path }: CestaPageProps) {
  const { citaPreviaState, dispatch } = useContext(AppContext)
  const [radioSelected, setRadioSelectd] = useState(null)
  const [error, setError] = useState(null)

  const onGoBack = () => {
    dispatch(resetCitaPrevia())
    navigate(route('area-privada.index'))
  }

  const handleRadioButton = e => {
    if (citaPreviaState.error) {
      dispatch(resetError())
    }
    setError(false)
    setRadioSelectd(e)
  }

  const handleSubmit = () => {
    if (!radioSelected) {
      setError(t('cita_previa.modificar.obligatorio'))
      return null
    }

    if (radioSelected === 'cancel') {
      dispatch(deleteCitaPrevia(citaPreviaState.citaPreviaId))
    }

    if (radioSelected === 'modify') {
      dispatch(
        setModifyingCitaPrevia({
          id_navision: citaPreviaState.citaPreviaId,
          hora: citaPreviaState.selectedDate,
          taller: citaPreviaState.selectedTaller.codigo_taller,
        })
      )
      if (citaPreviaState.selectedService && citaPreviaState.selectedService.vehicle === t('cita_previa.servicios.patinete')) {
        dispatch(
          setCitaPreviaStep(patineteCitaSteps[PatineteCitaPreviaEnum.MARCA].step)
        )
        navigate(patineteCitaSteps[PatineteCitaPreviaEnum.MARCA].route)
      } else {
        dispatch(
          setCitaPreviaStep(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].step)
        )
        navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route)
      }
    }
  }

  if (citaPreviaState.anulado) {
    Swal.fire(
      alertProp({
        type: 'success',
        text: t('cita_previa.modificar.anulada', {
          cita: citaPreviaState.citaPreviaId,
        }),
        title: t('cita_previa.modificar.gracias'),
      })
    ).then(() => {
      dispatch(resetCitaPrevia())
      navigate(route('area-privada.index'))
    })
  }

  return (
    <LayoutSelector
      title={t('cita_previa.modificar.title')}
      subTitle={t('cita_previa.modificar.subTitle')}
      hideSelectorSteps
      showCopyrightFooter>
      <div className={styles.horarios_container}>
        <div className={styles.horarios_container_left}>
          <p>
            {t('cita_previa.modificar.intro', {
              id: citaPreviaState.citaPreviaId,
            })}
          </p>
          <form onSubmit={handleSubmit} className={styles.options_container}>
            <RadioButtonYellow
              onChange={handleRadioButton}
              checked={radioSelected === 'modify'}
              label1={
                <div className={styles.label_title}>
                  {t('cita_previa.modificar.cambiar')}
                </div>
              }
              name="modify"
              value="modify"
            />

            <RadioButtonYellow
              onChange={handleRadioButton}
              checked={radioSelected === 'cancel'}
              label1={
                <div className={styles.label_title}>
                  {t('cita_previa.modificar.anular')}
                </div>
              }
              name="cancel"
              value="cancel"
            />
            <Error message={error} />
            {citaPreviaState.error ? (
              <Error message={citaPreviaState.error} />
            ) : null}
          </form>
          <Size.Matcher
            mobile={
              <div className={styles.button_cont}>
                <Button buttonClass="next_button" onClick={handleSubmit}>
                  <FormattedMessage id="selector-desktop.continuar" />
                  <Next />
                </Button>
              </div>
            }
            desktop={
              <CestaLastRow
                onContinuarClick={handleSubmit}
                onBackClick={onGoBack}></CestaLastRow>
            }
          />
        </div>
        <Size.Matcher
          mobile={null}
          desktop={<FunnelSideBar editCita citaPreviaState={citaPreviaState} />}
        />
      </div>
    </LayoutSelector>
  )
}

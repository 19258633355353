import React, { useContext, useEffect, useState } from 'react'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import AppContext from '../../../context/context'
import { patineteCitaSteps } from '../CitaPreviaSteps'
import { t } from '../../../i18n'
import styles from './index.module.scss'
import Size from '../../../utils/media-queries-setup'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import Patinete from '../../../assets/cita-previa/icon_patinete.svg'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import {
  getAvailableScooterBrands,
  setCitaPreviaStep,
  setScooterBrand,
} from '../../../context/actions/citaPreviaActions'
import { navigate } from 'gatsby'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'
import TabsWithoutRoute from '../../tabsAux/TabsWithoutRoute'
import Swal from 'sweetalert2'
import { alertProp } from '../../../utils/swal'

export default function MarcaPatinete({ path, nextStep }: CitaPreviaPageProps) {
  const { citaPreviaState, dispatch } = useContext(AppContext)
  const [selectedMarca, setSelectedMarca] = useState(undefined)
  // const [err, setError] = useState(citaPreviaState.error)

  useEffect(() => {
    dispatch(getAvailableScooterBrands())
  }, [])

  const onClinkBrand = (marca) => {
    setSelectedMarca(marca)
  }

  const onContinue = () => {
    if (!selectedMarca) {
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('cita_previa.patinete.required_marca'),
        })
      )
      return null
    }
    dispatch(setScooterBrand(selectedMarca))
    dispatch(setCitaPreviaStep(nextStep.step))
    navigate(nextStep.route, {
      state: { area_privada: true },
    })
    // }
  }

  const onClickBrandPatinete = async (marca) => {
    dispatch(setScooterBrand(marca))
    dispatch(setCitaPreviaStep(nextStep.step))
    navigate(nextStep.route, {
      state: { area_privada: true },
    })
  }

  const onGoBack = () => {
    navigate(-1)
  }

  return (
    <LayoutSelector
      stepActive={citaPreviaState.step}
      customSteps={patineteCitaSteps}
      title={t('cita_previa.patinete.title')}
      subTitle={t('cita_previa.patinete.subTitle')}
      mobileStep={citaPreviaState.step}
      totalSteps={patineteCitaSteps.length}
      showCopyrightFooter>
      <div className={styles.marca_patinete_container}>
        <div className={styles.marca_patinete_container_left}>
          <TabsWithoutRoute
            onChangeTab={() => {
              // nothing
            }}
            tabsArray={[
              {
                id: 1,
                tab: 'Patinete',
                displayName_ca: 'Patinet',
                displayName_es: 'Patinete',
                active: true,
                icon: <Patinete />,
              },
            ]}
            className={styles.tab}
          />
          <div className={styles.marcas_wrapper}>
            {t('cita_previa.patinete.selecciona_marca')}
            <div className={styles.marcas_container}>
              {citaPreviaState.scooter.scooterBrands
                ? citaPreviaState.scooter.scooterBrands.map((brand) => {
                    return (
                      <>
                        <Size.Matcher
                          mobile={
                            <button
                              key={brand.marca}
                              className={styles.marca_block}
                              onClick={() => onClickBrandPatinete(brand)}>
                              <img
                                src={brand.imagen}
                                alt={brand.marca}
                                className={
                                  selectedMarca &&
                                  selectedMarca.marca === brand.marca
                                    ? styles.selected_marca
                                    : ''
                                }
                              />
                              <p>{brand.marca.toUpperCase()}</p>
                            </button>
                          }
                          desktop={
                            <button
                              key={brand.marca}
                              className={styles.marca_block}
                              onClick={() => onClinkBrand(brand)}>
                              <img
                                src={brand.imagen}
                                alt={brand.marca}
                                className={
                                  selectedMarca &&
                                  selectedMarca.marca === brand.marca
                                    ? styles.selected_marca
                                    : ''
                                }
                              />
                              <p>{brand.marca.toUpperCase()}</p>
                            </button>
                          }
                        />
                      </>
                    )
                  })
                : null}
            </div>
          </div>
          <Size.Matcher
            mobile={null}
            desktop={
              <CestaLastRow
                onContinuarClick={onContinue}
                onBackClick={onGoBack}></CestaLastRow>
            }
          />
        </div>
        <Size.Matcher
          mobile={null}
          desktop={
            <FunnelSideBar
              currentLocation="cita-previa"
              citaPreviaState={citaPreviaState}
              area_privada={true}
            />
          }
        />
      </div>
    </LayoutSelector>
  )
}

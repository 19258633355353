import React, { useContext, useState } from 'react'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import AppContext from '../../../context/context'
import { areaPrivadaCitaSteps } from '../CitaPreviaSteps'
import { t } from '../../../i18n'
import styles from './index.module.scss'
import Size from '../../../utils/media-queries-setup'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import Next from '../../../assets/images/Talleres/next.svg'
import Button from '../../buttons/Button'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import {
  setCitaPreviaStep,
  setUserData,
} from '../../../context/actions/citaPreviaActions'
import { navigate } from 'gatsby'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'
import { textWithLocale } from '../../../../../sites/rodi/i18n'
import Error from '../../error/Error'

export default function Comment({ nextStep }: CitaPreviaPageProps) {
  const { citaPreviaState, dispatch } = useContext(AppContext)
  const [comment, setComment] = useState('')
  const [err, setError] = useState(citaPreviaState.error)

  const onContinue = () => {
    const { userData } = citaPreviaState
    if (!comment) {
      setError(
        t('validations.register.required', { label: t('formLabels.coment') })
      )
    } else {
      setError('')
      userData.comment = comment
      dispatch(setUserData(userData))
      dispatch(setCitaPreviaStep(nextStep.step))
      navigate(nextStep.route, {
        state: { area_privada: true },
      })
    }
  }

  const onBlurText = (inputComment: string) => {
    setComment(inputComment)
  }

  const onGoBack = () => {
    navigate(citaPreviaState.origin)
  }

  return (
    <LayoutSelector
      stepActive={citaPreviaState.step}
      customSteps={areaPrivadaCitaSteps}
      title={t('cita_previa.comentario.title')}
      subTitle={t('cita_previa.comentario.subTitle')}
      mobileStep={citaPreviaState.step}
      totalSteps={areaPrivadaCitaSteps.length}
      showCopyrightFooter>
      <div className={styles.comentario_container}>
        <div className={styles.comentario_container_left}>
          <div className={styles.comentario_wrapper}>
            <label>{t('area_privada.tu_vehiculo')}</label>
            <div>
              <p>
                {citaPreviaState.userData.marca}{' '}
                {citaPreviaState.userData.modelo}
                {' | '}
                {citaPreviaState.userData.matricula}
              </p>
            </div>
          </div>
          <div className={styles.comentario_wrapper}>
            <label>{t('area_privada.motivo_cita')}</label>
            <div>
              <p>{citaPreviaState.selectedService[textWithLocale('nombre')]}</p>
            </div>
          </div>
          <div className={styles.comentario_wrapper}>
            <label>{t('registerForm.coment')}</label>
            <div>
              <textarea
                name="comment"
                onBlur={(e) => onBlurText(e.target.value)}
                rows={2}
                defaultValue={
                  (citaPreviaState.userData &&
                    citaPreviaState.userData.comment) ||
                  ''
                }
              />
              {err ? err && <Error message={err}></Error> : null}
            </div>
          </div>
          <Size.Matcher
            mobile={
              <div className={styles.button_cont}>
                <Button buttonClass="next_button" onClick={onContinue}>
                  {t('selector-desktop.continuar')}
                  <Next />
                </Button>
              </div>
            }
            desktop={
              <CestaLastRow
                onContinuarClick={onContinue}
                onBackClick={onGoBack}></CestaLastRow>
            }
          />
        </div>
        <Size.Matcher
          mobile={null}
          desktop={
            <FunnelSideBar
              currentLocation="cita-previa"
              citaPreviaState={citaPreviaState}
              area_privada={true}
            />
          }
        />
      </div>
    </LayoutSelector>
  )
}

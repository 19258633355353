/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup'
import { t } from '../../../../common/i18n'
import { validatePhone } from '../../../../specific/helpers/validation/validationPhone'

export interface InDatosUsuarioFields {
  name: string
  email: string
  phone: string
  matricula: string
  marca: string
  modelo: string
  matricula_moto: string
  checkboxPrivacy: boolean
  checkboxRenting: boolean
  comment: string
}
export const datosUsuarioFields = {
  name: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.name') })
    ),
  email: yup
    .string()
    .email(t('validations.register.format', { label: t('formLabels.email') }))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  phone: yup
    .string()
    .test('test-phone_number', '${message}', validatePhone)
    .required(
      t('validations.register.required', { label: t('formLabels.phone') })
    ),
  matriculaMarcaModelo: yup.string(),
  matricula: yup.string().when(['matriculaMarcaModelo', 'matricula_moto'], {
    is: (matriculaMarcaModelo, matriculaMoto) =>
      !!matriculaMarcaModelo || !!matriculaMoto,
    then: yup.string(),
    otherwise: yup
      .string()
      .matches(
        process.env.GATSBY_WEB === 'rodimotor'
          ? /^(([A-Z]{2}(-|)\d{2}(-|)(\d{2}|[A-Z]{2}))|(\d{2}(-|)(\d{2}(-|)[A-Z]{2}|[A-Z]{2}(-|)\d{2})))$/
          : /^([0-9]{4}(-| |)[A-Za-z]{3}|[A-Za-z]{1,2}?(-| |)[0-9]{4}(-| |)[a-zA-Z]{1,2}?)$/,
        t('validations.license.format')
      )
      .required(t('validations.license.required')),
  }),
  matricula_moto: yup.string().when(['matriculaMarcaModelo', 'matricula'], {
    is: (matriculaMarcaModelo, matricula) =>
      !!matriculaMarcaModelo || !!matricula,
    then: yup.string(),
    otherwise: yup
      .string()
      .matches(/^[A-Za-z0-9-]+$/, t('validations.license.format_moto'))
      .required(t('validations.license.required')),
  }),
  marca: yup.string().when('matriculaMarcaModelo', {
    is: (value) => value,
    then: yup.string(),
    otherwise: yup.string().required(t('validations.marca.required')),
  }),
  modelo: yup.string().when('matriculaMarcaModelo', {
    is: (value) => value,
    then: yup.string(),
    otherwise: yup.string().required(t('validations.modelo.required')),
  }),
  checkboxPrivacy: yup
    .boolean()
    .oneOf([true], t('validations.register.accept_terms')),
  checkboxRenting: yup
    .boolean()
    .oneOf([true], t('validations.register.required', { label: '' })),
  comment: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.coment') })
    ),
}

export interface InDatosUsuarioFieldsPatinete {
  name: string
  email: string
  phone: string
  checkboxPrivacy: boolean
  comment: string
}

export const datosUsuarioPatineteFields = {
  name: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.name') })
    ),
  email: yup
    .string()
    .email(t('validations.register.format', { label: t('formLabels.email') }))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  phone: yup
    .string()
    .test('test-phone_number', '${message}', validatePhone)
    .required(
      t('validations.register.required', { label: t('formLabels.phone') })
    ),
  checkboxPrivacy: yup
    .boolean()
    .oneOf([true], t('validations.register.accept_terms')),
  comment: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.coment') })
    ),
}
